.header-wrapper {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    background-color: var(--white);
    height: 60px;
    z-index: 10;
}

header {
    height: 60px;
    display: flex;
    justify-content: space-between;
    padding: 1rem 0rem;
    width: 100vw;
    margin: 0 auto;
    max-width: 1200px;
    z-index: 2;
    /* background-color: red; */
    /* background-color: var(--white); */
    align-items: center;
}

.brand {
    width: 12rem;
}

.brand img {
    width: 100%;
}

.brand svg {
    fill: var(--blue);
}


.menu_wrapper {
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: flex-end;
    width: 100%;
}

.menu {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    font-size: 1rem;
    font-weight: 400;
    letter-spacing: 0px;
    color: var(--blue);
    text-transform: capitalize;
}

.menu a {
    margin: 0 1.5rem;
    transition: all 0.2s ease;
    opacity: 0.8;
}

.menu a:hover {
    opacity: 1;
    /* font-weight: 600; */
    color: var(--pink);
}

.menu_burger {
    display: none;
    cursor: pointer;
}

.menu_burger_bar {
    width: 100%;
    height: .2rem;
    background-color: var(--pink);
    /* background-image: linear-gradient(to right, #3D2BFF, #19A4FE); */
    border-radius: .4rem;
    transform-origin: center center;
    transition: .3s cubic-bezier(.16, 1, .3, 1);
}


@media screen and (max-width: 500px) {
    header {
        padding: 1rem 2rem;
        height: 80px;
        display: flex;
        justify-content: space-between;
        /* padding: 1rem 0rem; */
        position: fixed;
        top: 0;
        left: 0;
        width: 100vw;
        margin: 0 auto;
        z-index: 2;
        background-color: var(--white);
        align-items: center;
    }

    .menu_active {
        display: flex;
        transition: .3s cubic-bezier(.16, 1, .3, 1);
        transform: translateY(0);
        opacity: 1;
    }

    .menu_active .brand {
        fill: var(--white);
    }

    .menu_wrapper {
        display: flex;
        justify-content: flex-start;
        flex-direction: column;
        width: 100%;
        height: 100vh;
        padding: 10% 0;
        position: fixed;
        top: 0;
        left: 0;
        background-color: var(--blue);
        transform: translateY(-100%);
        opacity: 0;
    }

    .menu_active .menu_wrapper {
        display: flex;
        transition: .3s cubic-bezier(.16, 1, .3, 1);
        transform: translateY(0);
        opacity: 1;
        z-index: 2;
    }

    .menu_wrapper .menu {
        display: flex;
        justify-content: flex-start;
        flex-direction: column;
    }

    .menu_wrapper .menu a {
        padding: 1.8rem 0;
        /* border-bottom: dotted 1px var(--grey); */
        color: var(--white);
        font-size: 1.3rem;
        font-weight: 500;
    }

    .menu_wrapper .menu .pink-button {
        color: var(--white);
        padding: 0.5rem 1rem;
        background-color: var(--pink);
        border: 1px solid var(--pink);
        cursor: pointer;
        font-weight: 500;
        /* width: fit-content; */
        text-align: center;
        margin-top: 4rem;
    }


    .menu_burger {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-direction: column;
        width: 1.8rem;
        height: 1.4rem;
        position: relative;
        z-index: 10;
    }

    .menu_active .menu_burger_bar-1 {
        transform: rotate(-45deg) translateX(-.85rem);
    }

    .menu_active .menu_burger_bar-2 {
        opacity: 0;
    }

    .menu_active .menu_burger_bar-3 {
        transform: rotate(45deg) translateX(-.85rem);
    }
}