footer {
    background-color: var(--black);
    margin-top: 5rem;
    width: 100%;
    padding: 4rem;
    /* height: 25rem; */
    position: relative;
}

.footer_flex {
    display: flex;
    align-items: flex-start;
}

.brand_footer {
    width: 12rem;
}

.brand_footer svg {
    fill: var(--white);
}

.footer-title {
    color: var(--white);
    font-weight: 700;
    margin-bottom: 1rem;
}

.footer-link {
    color: var(--light-grey);
    font-weight: 500;
    /* margin-bottom: 1rem; */
}

.footer-link:hover {
    color: var(--white);
}

.footer_sm_icon {
    opacity: 0.9;
    filter: brightness(0) invert(1);
    height: 2rem;
    width: 2rem;
    margin: 1rem 1rem 0 0;
}

.copyright {
    width: 100%;
    display: flex;
    justify-content: center;
    color: var(--light-grey);
    position: absolute;
    bottom: 2rem;
}