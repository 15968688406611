/* @import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;700;800&display=swap'); */
@import url('https://fonts.googleapis.com/css2?family=Dancing+Script:wght@700&family=Montserrat:wght@400;500;700&display=swap');

:root {
    /* FONT */
    --font: 'Montserrat', sans-serif;
    --hand: 'Dancing Script', cursive;

    /* COLORS */
    --bg-color: #FFFFFF;
    /* --blue: #002A4E; */
    --blue: #005097;
    --pink: #FE869C;
    --light-pink: #ffccd5;
    --dim-pink: #fe869c0f;
    --green: #16BAC5;
    --light-blue: #5FBFF9;

    --black: #222C37;
    --white: #FFFFFF;

    /* GREYS */
    --dark-grey: #5f6469;
    --grey: #707A84;
    --light-grey: #C8CAD4;
    --dim-grey: #d8dae0;
    --dimmer-grey: #F4F6F5;
}



/* MAIN BODY */
* {
    box-sizing: border-box;
}

html {
    scroll-behavior: smooth;
    width: 100%;
    height: 100%;
    font-size: 15px;
    background-color: var(--bg-color);
    overflow-x: hidden;
}


body {
    margin: 0 auto;
    padding: 0;
    overflow-x: hidden;
    display: block;
    min-height: 100vh;
    width: 100vw;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
}


a {
    text-decoration: none;
    color: inherit;
}

body,
p {
    color: var(--black);
    font: 400 1rem/1.625rem var(--font);
    margin: 0;
}

p.small {
    font: 400 0.8rem/1.225rem var(--font);
}


p.italic,
.italic {
    font-style: italic;
}

p.bold {
    font-weight: 700;
}

p.white {
    color: var(--white);
}

p.grey {
    color: var(--light-grey);
}


h1 {
    color: var(--blue);
    font: 500 3.5rem/5rem var(--font);
    letter-spacing: -2px;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    margin: 0;
    margin-bottom: 2rem;
}

h1 .rounded {
    color: var(--blue);
    font: 700 4.5rem/5rem var(--hand);
    letter-spacing: -2px;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
}

h2 {
    color: var(--blue);
    font: 500 2.8rem/4rem var(--font);
    letter-spacing: -2px;
    margin: 0;
    padding: 0;
}

h2.white {
    color: var(--white);
}

h3 {
    color: var(--headers);
    font: 500 1.8rem/2.125rem var(--font);
    letter-spacing: -0.2px;
    margin-bottom: 0rem;
}

h3.white {
    color: var(--white);
}

h4 {
    color: var(--grey);
    font: 500 1.375rem/2.125rem var(--font);
    letter-spacing: -0.1px;
    margin: 0;
}

h5 {
    color: var(--headers);
    font: 500 1.25rem/1.5rem var(--font);
    letter-spacing: -0.1px;
    margin: 0;
}

h6 {
    color: var(--headers);
    font: 500 1rem/1.275rem var(--font);
    letter-spacing: -0.1px;
    margin: 0;
}


/* FLEX */

.row {
    display: flex;
    flex-wrap: wrap;
}

.row.reversedwrap {
    flex-wrap: wrap-reverse;
}

.row.nowrap {
    flex-wrap: nowrap;
}

.row.sp_around {
    justify-content: space-around;
}

.row.vcentered {
    align-items: center;
}

.relative {
    position: relative;
}

.col {
    display: flex;
    flex-direction: column;
}

.col.centered {
    align-items: center;
}

.col.vcentered {
    justify-content: center;
}

.column-flex-start {
    align-items: flex-start !important;
}


.flex-1 {
    flex: 1;
    /* min-width: 400px; */
}

.flex-2 {
    flex: 2;
}

.flex-3 {
    flex: 3;
    min-width: 300px;
}

.flex-4 {
    flex: 4;
}



/* MARGINS */

.margin-s1 {
    margin-left: 1rem;
    margin-right: 1rem;
}

.margin-t1 {
    margin-top: 1rem;
}

.margin-t2 {
    margin-top: 2rem !important;
}

.margin-t4 {
    margin-top: 4rem;
}

.padding-s4 {
    padding-left: 4rem !important;
    padding-right: 4rem !important;
}

.padding-t4 {
    padding-top: 4rem;
}

.padding-t8 {
    padding-top: 8rem;
}

.padding-b4 {
    padding-bottom: 4rem;
}

.padding-b4 {
    padding-bottom: 4rem;
}

.padding-b8 {
    padding-bottom: 8rem;
}

.min-h-500 {
    min-height: 500px;
}


/* BUTTONS */

.pink-button {
    color: var(--pink);
    padding: 0.5rem 1rem;
    border: 1px solid var(--pink);
    cursor: pointer;
    font-weight: 500;
    width: fit-content;
}

.pink-button:hover {
    color: var(--white) !important;
    background-color: var(--pink);
    transition: all 0.6s ease;
}

.pink-inverted-button-hero {
    color: var(--white);
    padding: 0.5rem 1rem;
    background-color: var(--pink);
    border: 1px solid var(--pink);
    cursor: pointer;
    font-weight: 500;
    /* width: fit-content; */
    text-align: center;
}

.pink-inverted-button-hero:hover {
    color: var(--pink) !important;
    border: 1px solid var(--pink);
    background-color: var(--white);
    transition: all 0.6s ease;
}

.pink-inverted-button {
    color: var(--white);
    padding: 0.5rem 1rem;
    background-color: var(--pink);
    border: 1px solid var(--pink);
    cursor: pointer;
    font-weight: 700;
    /* width: fit-content; */
    text-align: center;
    border-radius: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.pink-inverted-button:hover {
    color: var(--pink) !important;
    border: 1px solid var(--pink);
    background-color: var(--white);
    transition: all 0.6s ease;
}

.pink-inverted-button svg {
    height: 1rem;
    margin-right: 1rem;
    fill: var(--white);
}


/* IMAGES */

.img-render {
    image-rendering: -moz-crisp-edges;
    /* Firefox */
    image-rendering: -o-crisp-edges;
    /* Opera */
    image-rendering: -webkit-optimize-contrast;
    /* Webkit (non-standard naming) */
    image-rendering: crisp-edges;
    -ms-interpolation-mode: nearest-neighbor;
    /* IE (non-standard property) */
}

.img-fluid {
    width: 100%;
    min-width: 300px;
}

.img-rounded {
    border-radius: 30px;
}

.img-shadow {
    box-shadow:
        0px 0px 2.2px rgba(0, 0, 0, 0.02),
        0px 0px 5.3px rgba(0, 0, 0, 0.028),
        0px 0px 10px rgba(0, 0, 0, 0.035),
        0px 0px 17.9px rgba(0, 0, 0, 0.042),
        0px 0px 33.4px rgba(0, 0, 0, 0.05),
        0px 0px 80px rgba(0, 0, 0, 0.07);
}

.img-absolute {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    opacity: 0.8;
}

.img-180 {
    max-width: 180px;
}

.video {
    position: relative;
}


.video video {
    width: 100%;
}

/* SECTIONS */

.content_section {
    margin: 0 auto;
    max-width: 1200px;
    width: 100%;
    padding: 4rem;
}

.section_main_title {
    display: flex;
    justify-content: center;
    align-items: center;
}

.section_main_title svg {
    fill: var(--blue);
    height: 2.5rem;
    margin: 0 1rem;
}

.section_main_title svg.white {
    fill: var(--white);
}

.section_title {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.section_title svg {
    fill: var(--blue);
    height: 2.5rem;
    margin: 0 1rem;
}

/* HERO */

.hero {
    height: 600px;
    width: 100%;
    margin-top: 9rem;
    margin-bottom: 12rem;
    flex-wrap: nowrap;
    padding: 0 !important;
}

.headline-column {
    width: 400px;
    position: relative;
    /* height: fit-content; */
    /* background-color: red; */
}

.dots {
    position: absolute;
    top: -3rem;
    left: 71px;
    width: 142px;
    height: 147px;
    /* margin: 1rem auto; */
    z-index: 2;
}

.dots img {
    width: 100%;
}

.headline-box {
    position: absolute;
    top: 4rem;
    left: 0rem;
    background-color: rgba(255, 255, 255, 0.3);
    -webkit-backdrop-filter: blur(20px);
    backdrop-filter: blur(20px);
    width: 550px;
    padding: 2rem;
    z-index: 1;
}


.hero-carousel {
    width: 100%;
    max-height: 600px;
    display: flex;
}

.hero-carousel .carousel-root {
    max-height: 600px;
    width: 100%;
}

.hero-carousel .carousel-img {
    width: 100%;
    object-fit: cover;
    max-height: 600px;
}

.hero-carousel .controls {
    /* width: 50px; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 1rem;
}

.hero-carousel .controls svg {
    height: 2.5rem;
    fill: var(--pink);
    margin: 1rem 0;
    cursor: pointer;
}


/* PROCESS */


.process {
    width: 100%;
    height: 4200px;
}

.process_sticky {
    position: -webkit-sticky;
    position: sticky;
    top: 8rem;
    height: 600px;
}



.img-process {
    opacity: 0;
    transition: 600ms;
}

.img-process-0 {
    opacity: 1;
    position: absolute;
    top: 0px;
    left: 0px;
    z-index: 1;
}

.img-process-1 {
    opacity: 1;
    position: absolute;
    top: 30px;
    left: 30px;
    z-index: 2;
}

.img-process-2 {
    opacity: 1;
    position: absolute;
    top: 60px;
    left: 60px;
    z-index: 3;
}

.img-process-3 {
    opacity: 1;
    position: absolute;
    top: 90px;
    left: 90px;
    z-index: 4;
}

.img-process-4 {
    opacity: 1;
    position: absolute;
    top: 120px;
    left: 120px;
    z-index: 5;
}

.img-process-5 {
    opacity: 1;
    position: absolute;
    top: 150px;
    left: 150px;
    z-index: 6;
}

.img-process-6 {
    opacity: 1;
    position: absolute;
    top: 180px;
    left: 180px;
    z-index: 7;
}


/* TESTIMONIALS */

.testimonials {
    margin-top: 1rem;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    /* padding: 4rem; */
    /* background-color: var(--dimmer-grey); */
    background-color: var(--dim-pink);
}

.testimonials .title {
    font-size: 1.4rem;
    text-transform: uppercase;
    font-weight: 500;
    color: var(--blue);
    text-align: center;
}

.blue-bg-section {
    background-color: var(--blue);
    width: 100%;
    /* padding: 4rem; */
}

.pink-bg-section {
    background-color: var(--dim-pink);
    width: 100%;
    /* padding: 4rem; */
}


/* FORM */

.form {
    display: flex;
    flex-direction: column;
    max-width: 600px;
    margin: 2rem auto;
}

label {
    color: var(--black);
    font-size: 1rem;
    margin-bottom: 0.5rem;
    line-height: 1rem;
}

label sup {
    font-size: 1rem;
    color: var(--pink);
}

input[type="text"],
input[type="text"]:focus,
input[type="text"]:active,
input[type="email"],
input[type="email"]:focus,
input[type="email"]:active {
    background-color: var(--white);
    padding: 20px;
    border: 1px solid var(--dark-grey);
    /* margin-left: 1rem; */
    border-radius: 20px;
    color: var(--black);
    outline: none;
    /* font-size: clamp(1rem, 1vw + 1rem, 1.5rem); */
    /* width: fit-content; */
    /* min-width: 20rem; */
    width: 100%;
}

textarea {
    background-color: var(--white);
    padding: 20px;
    border: 1px solid var(--dark-grey);
    border-radius: 20px;
    color: var(--black);
    outline: none;
    width: 100%;
}

.input-error {
    border: 1px solid var(--pink) !important;
}

.sent-message {
    margin-top: 4rem;
    background-color: var(--green);
    color: var(--white);
    padding: 2rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
}

/* LEARN MORE */


.learn_more {
    font-weight: 500;
    color: var(--pink);
}

.learn_more.learn_more_white {
    color: var(--white);
}

.learn_more::after {
    content: ' ';
    position: relative;
    top: 0px;
    width: 16px;
    background-image: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M8.90533 2.96967C8.61244 2.67678 8.13756 2.67678 7.84467 2.96967C7.55178 3.26256 7.55178 3.73744 7.84467 4.03033L11.0643 7.25H3.125C2.71079 7.25 2.375 7.58579 2.375 8C2.375 8.41421 2.71079 8.75 3.125 8.75H11.0643L7.84467 11.9697C7.55178 12.2626 7.55178 12.7374 7.84467 13.0303C8.13756 13.3232 8.61244 13.3232 8.90533 13.0303L13.4053 8.53033C13.6982 8.23744 13.6982 7.76256 13.4053 7.46967L8.90533 2.96967Z' fill='%23FE869C'/%3E%3C/svg%3E%0A");
    height: 16px;
    background-repeat: no-repeat;
    background-position: left center;
    background-size: 16px;
    line-height: 100%;
    vertical-align: middle;
    display: inline-block;
    margin-left: 4px;
    transition: transform 0.25s ease-in-out;
}

.learn_more.learn_more_white::after {
    content: ' ';
    position: relative;
    top: 0px;
    width: 16px;
    background-image: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M8.90533 2.96967C8.61244 2.67678 8.13756 2.67678 7.84467 2.96967C7.55178 3.26256 7.55178 3.73744 7.84467 4.03033L11.0643 7.25H3.125C2.71079 7.25 2.375 7.58579 2.375 8C2.375 8.41421 2.71079 8.75 3.125 8.75H11.0643L7.84467 11.9697C7.55178 12.2626 7.55178 12.7374 7.84467 13.0303C8.13756 13.3232 8.61244 13.3232 8.90533 13.0303L13.4053 8.53033C13.6982 8.23744 13.6982 7.76256 13.4053 7.46967L8.90533 2.96967Z' fill='%23FFFFFF'/%3E%3C/svg%3E%0A");
    height: 16px;
    background-repeat: no-repeat;
    background-position: left center;
    background-size: 16px;
    line-height: 100%;
    vertical-align: middle;
    display: inline-block;
    margin-left: 4px;
    transition: transform 0.25s ease-in-out;
}

.learn_more:hover::after {
    transform: translateX(3px);
}


/* SCROLL */

.scroll-to-top {
    position: fixed;
    bottom: 30px;
    right: 30px;
    height: 50px;
    width: 50px;
    border-radius: 10px;
    padding: 1rem;
    cursor: pointer;
    background-color: rgba(0, 0, 0, .32);
    backdrop-filter: saturate(100%) blur(10px);
    z-index: 1000;
}

.scroll-to-top svg {
    fill: var(--white);
}

/* LOADER */

.lds-ellipsis {
    display: inline-block;
    position: relative;
    width: 20px;
    height: 20px;
}

.lds-ellipsis div {
    position: absolute;
    top: 8px;
    width: 13px;
    height: 13px;
    border-radius: 50%;
    background: var(--light-pink);
    animation-timing-function: cubic-bezier(0, 1, 1, 0);
}

.lds-ellipsis div:nth-child(1) {
    left: 8px;
    animation: lds-ellipsis1 0.6s infinite;
}

.lds-ellipsis div:nth-child(2) {
    left: 8px;
    animation: lds-ellipsis2 0.6s infinite;
}

.lds-ellipsis div:nth-child(3) {
    left: 32px;
    animation: lds-ellipsis2 0.6s infinite;
}

.lds-ellipsis div:nth-child(4) {
    left: 56px;
    animation: lds-ellipsis3 0.6s infinite;
}

@keyframes lds-ellipsis1 {
    0% {
        transform: scale(0);
    }

    100% {
        transform: scale(1);
    }
}

@keyframes lds-ellipsis3 {
    0% {
        transform: scale(1);
    }

    100% {
        transform: scale(0);
    }
}

@keyframes lds-ellipsis2 {
    0% {
        transform: translate(0, 0);
    }

    100% {
        transform: translate(24px, 0);
    }
}

/* LISTS */

ul {
    list-style: none;
}

ul li::before {
    content: "\2022";
    color: var(--pink);
    font-weight: bold;
    display: inline-block;
    width: 1em;
    margin-left: -1em;
}


/* PICTO DIVS */
.picto-wrapper {
    display: flex;
    flex-wrap: nowrap;
    justify-content: center;
    height: 500px;
    width: 100%;
}

.picto-txt-wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-right: 1rem;
}

.picto-txt {
    font-size: 1rem;
    font-weight: 500;
    color: var(--pink);
    font-style: italic;
    margin: 2rem 0 5rem 0;
}

.picto-img {
    height: 500px;
}


@media screen and (max-width: 500px) {

    h1 {
        font: 500 2rem/2.5rem var(--font);

    }

    h1 .rounded {
        font: 700 2.8rem/3rem var(--hand);
    }

    h2 {
        font: 500 1.6rem/2rem var(--font);

    }

    h3 {
        font: 500 1.3rem/1.925rem var(--font);

    }

    h4 {
        font: 500 1rem/1.5rem var(--font);

    }

    body,
    p {
        font: 400 0.8rem/1.225rem var(--font);
    }

    .section_main_title svg {
        height: 1.4rem;
    }

    .section_title svg {
        height: 1.4rem;
    }

    .flex-1.mobile {
        display: none;
    }

    .content_section {
        padding: 2rem;
    }

    .hero {
        flex-wrap: wrap;
        margin-top: 8rem;
    }

    .headline-column {
        height: 350px;
    }

    .dots {
        /* display: none; */
        position: absolute;
        top: -3rem;
        left: 20px;
        width: 99px;
        height: 103px;
        /* margin: 1rem auto; */
        z-index: 2;
    }

    .headline-box {
        position: absolute;
        top: 2rem;
        left: 0rem;
        background-color: rgba(255, 255, 255, 0.3);
        -webkit-backdrop-filter: blur(20px);
        backdrop-filter: blur(20px);
        width: 400px;
        padding: 2rem;
        z-index: 1;
    }


    .hero-carousel {
        width: 100%;
        max-height: 300px;
        display: flex;
    }

    .hero-carousel .carousel-root {
        max-height: 300px;
        width: 100%;
    }

    .hero-carousel .carousel-img {
        width: 100%;
        max-height: 300px;
    }

    .hero-carousel .controls svg {
        height: 2rem;
    }

    .testimonials-img img {
        max-height: 60px;
    }

    footer {
        padding: 1rem;
    }

    .footer_flex {
        display: flex;
        flex-direction: column;
        /* align-items: flex-start; */
    }
}

@media screen and (max-width: 400px) {
    .headline-column {
        width: 335px;
    }

    .headline-box {
        position: absolute;
        top: 2rem;
        left: 0rem;
        background-color: rgba(255, 255, 255, 0.3);
        -webkit-backdrop-filter: blur(20px);
        backdrop-filter: blur(20px);
        width: 365px;
        padding: 2rem;
        z-index: 1;
    }
}